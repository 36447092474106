<template>
    <v-row no-gutters class="tempColor">
      <v-col cols="12" md="8" class="pa-2" offset-md="2">
        <v-simple-table :fixed-header="true" height="90vh" dense class="elevation-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">TypeId</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">TypeName
                </th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Balance</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item,index) in balanceList" :key="index" @click="selectOneClick(item)"
                :style="{backgroundColor: (item.typeId == selectedOne.typeId ? '#def3ff' : 'transparent' ) }">
                <td class="text-center">{{ index+1}}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.typeId }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td>{{ item.typeName }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right red--text"><h3>{{ item.amount|doubleFormat }}</h3></td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">
                    <v-btn small icon color="green" @click="clickHistory(item)">
                  <v-icon>history</v-icon></v-btn>
                </td>
              </tr>
              <v-divider/>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
      <v-col>
        <v-bottom-sheet v-model="detailDialog" fullscreen scrollable>
      <v-sheet>
        <balanceDetail
          @closeDialog="detailDialog=false"
          :balance="balance"
        />
      </v-sheet>
    </v-bottom-sheet>
      </v-col>
    </v-row>
  </template>
  <script>
    import billService from "../service/BillService";
    import balanceDetail from "@/components/BalanceDetail.vue";
  export default {
    data: () => ({
      selectedOne:{},
      balanceList:[],
      balance:{},
      detailDialog:false
    }),
    props: {},
    mounted: function() {
        this.balanceListMethod();
    },
    methods: {
      clickHistory:function(item){
          this.detailDialog = true;
          this.balance = Object.assign({},item);
      },
      selectOneClick:function(item){
         this.selectedOne = item;
       },
       balanceListMethod:function(){
        billService
          .getBalanceLeft()
          .then((response) => {
            this.balanceList.splice(0, this.balanceList.length);
            this.balanceList.push(...response);
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
       },
    },
    watch: {},
    components: {balanceDetail}
  };
  </script>
  <style scoped>
  .v-data-table td,
  .v-data-table th {
    padding: 0 5px !important;
  }
  </style>
        // eslint-disable-next-line
        /* eslint-disable */ 