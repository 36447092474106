<template>
    <v-row no-gutters class="tempColor">
        <v-col cols="12">
      <v-toolbar dark color="primary pl-2 pr-2" dense>
        <v-toolbar-title>{{balance.typeName}} Detail</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closeDialog')">
          <v-icon>cancel</v-icon>
        </v-btn>
      </v-toolbar>
    </v-col>
    <v-col cols="12" md="12">
        <v-row no-gutters class="mt-2 ml-2">
        <v-col cols="5" md="2" offset-md="2" >
          <v-menu
            v-model="fromMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="fromDate"
                label="From Date"
                readonly
                v-on="on"
                hide-details
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromPicker"
              @input="fromMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="5" md="2" class="pl-2">
          <v-menu
            v-model="toMenu"
            full-width
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="toDate"
                label="To Date"
                readonly
                v-on="on"
                hide-details
                dense
                outlined
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toPicker"
              @input="toMenu = false"
              color="primary"
              :max="new Date().toISOString().substr(0, 10)"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="2" md="1" class="pl-3 pt-1">
          <v-btn icon class="primary" @click="searchMethod()" >
            <v-icon color="white"> search </v-icon>
          </v-btn>
        </v-col>
    </v-row>
    </v-col>
      <v-col cols="12" md="8" class="pa-2" offset-md="2">
        <v-simple-table :fixed-header="true" height="83vh" dense class="elevation-1">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center white--text primary">No.</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Date</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Type Payment</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Opening
                </th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Amount</th>
                <th class="text-center white--text primary">
                  <v-divider vertical></v-divider>
                </th>
                <th class="text-center white--text primary">Balance</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for=" (item,index) in balanceList" :key="index" @click="selectOneClick(item)"
                :style="{backgroundColor: (item.receivedDate == selectedOne.receivedDate ? '#def3ff' : 'transparent' ) }">
                <td class="text-center">{{ index+1}}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-center">{{ item.receivedDate }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td>{{ item.message }}</td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right blue--text"><h3>{{ item.opening|doubleFormat }}</h3></td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right red--text"><h3>{{ item.amount|doubleFormat }}</h3></td>
                <td class="text-center"><v-divider vertical></v-divider></td>
                <td class="text-right black--text"><h3>{{ item.balance|doubleFormat }}</h3></td>
              </tr>
              <v-divider/>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </template>
  <script>
    import billService from "../service/BillService";
  export default {
    data: () => ({
      selectedOne:{},
      balanceList:[],
      product:{},
      fromPicker: new Date().toISOString().substr(0, 10),
    toPicker: new Date().toISOString().substr(0, 10),
    fromMenu: false,
    toMenu: false,
    fromDate: "",
    toDate: "",
    }),
    props: {balance:Object},
    mounted: function() {
        this.fromDate = this.formatDate(this.fromPicker);
        this.toDate = this.formatDate(this.toPicker);
        this.searchMethod();
    },
    methods: {
        formatDate(fromPicker) {
      const [year, month, day] = fromPicker.split("-");
      return `${day}-${month}-${year}`;
    },
      selectOneClick:function(item){
         this.selectedOne = item;
       },
       searchMethod:function(){
        billService
          .getBalanceDetail( this.fromDate,this.toDate,this.balance.typeId)
          .then((response) => {
            this.balanceList.splice(0, this.balanceList.length);
            this.balanceList.push(...response);
          })
          .catch((error) => {
            this.$swal("Fail!", error.response.data.message, "error");
          });
       },
    },
    watch: {
        fromPicker() {
      this.fromDate = this.formatDate(this.fromPicker);
    },
    toPicker() {
      this.toDate = this.formatDate(this.toPicker);
    },
        balance:function(){
            this.fromPicker =  new Date().toISOString().substr(0, 10),
            this.toPicker =  new Date().toISOString().substr(0, 10),
            this.fromDate = this.formatDate(this.fromPicker);
            this.toDate = this.formatDate(this.toPicker);
            this.searchMethod();
        }
    },
    components: {}
  };
  </script>
  <style scoped>
  .v-data-table td,
  .v-data-table th {
    padding: 0 5px !important;
  }
  </style>
        // eslint-disable-next-line
        /* eslint-disable */ 